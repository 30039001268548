/* =================================================================

    ページトップボタン

================================================================= */

export const scrollTop = function () {
  //ボタン
  const pagetopBtn = document.querySelector(".js-top-scroll");

  //クリックイベントを追加
  pagetopBtn.addEventListener("click", () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  //スクロール時のイベントを追加
  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 300) {
      pagetopBtn.style.opacity = "1";
      pagetopBtn.style.pointerEvents = "all";
    } else if (window.pageYOffset < 300) {
      pagetopBtn.style.opacity = "0";
      pagetopBtn.style.pointerEvents = "none";
    }

    // const scrollHeight = document.body.clientHeight;
    // const scrollPosition = window.innerHeight + window.pageYOffset;
    // const footHeight = document.getElementsByClassName("l-footer")[0].clientHeight;

    // console.log(footHeight);

    // if (scrollHeight - scrollPosition <= footHeight) {
    //   pagetopBtn.style.position = "absolute";
    //   pagetopBtn.style.bottom = footHeight + 30 + "px";
    // } else {
    //   pagetopBtn.style.position = "fixed";
    //   pagetopBtn.style.bottom = 30 + "px";
    // }
  });
};
