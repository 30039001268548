// import { wf } from "./lib/wf";
// import { common } from "./lib/common";
import { drawer } from "./lib/drawer";
import { dropdown } from "./lib/dropdown";
import { scrollTop } from "./lib/scroll-top";
import { inview } from "./lib/inview";
import { modaal } from "./lib/modaal";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();
// wf();
// common();
modaal();
drawer();
dropdown();
scrollTop();
inview();

/* =================================================================
  jsが使用可能の場合classを付与
================================================================= */

document.documentElement.classList.add("is-availableJs");

/* =================================================================

  hero キャッチアニメーション

================================================================= */

window.addEventListener("DOMContentLoaded", () => {
  const header = document.querySelector(".js-header");
  const heroCatch = document.querySelector(".p-hero__catch");
  header.classList.add("is-loaded");
  heroCatch.classList.add("is-loaded");
});

/* =================================================================
  swiper
================================================================= */

import Swiper from "swiper/bundle";
const mySwiper = new Swiper(".js-slider", {
  loop: true,
  effect: "fade",
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  speed: 2000,
  // autoplayDisableOnInteraction: false,
  slidesPerView: "auto",
  loopedSlides: 1,
  centeredSlides: true,
  slideToClickedSlide: true,
  // spaceBetween: 40,
  // pagination: {
  //   el: ".js-swiper-pagination",
  //   type: "bullets",
  //   clickable: true,
  //   bulletElement: "div",
  // },
  // navigation: {
  //   nextEl: ".js-swiper-button-next",
  //   prevEl: ".js-swiper-button-prev",
  // },

  // on: {
  //   slideChange: () => {
  //     if (mySwiper.realIndex > 0) {
  //       mySwiper.params.autoplay.delay = 2700;
  //       barDuration = 2700;
  //     }
  //   },
  //   // スライド（次または前）へのアニメーションの開始時にイベント発生
  //   slideChangeTransitionStart: function (result) {
  //     bar.style.transitionDuration = "0s";
  //     bar.style.transform = "scaleX(0)";
  //   },
  //   // // スライド（次または前）へのアニメーションの開始後にイベント発生
  //   slideChangeTransitionEnd: function (result) {
  //     bar.style.transitionDuration = barDuration + "ms";
  //     bar.style.transform = "scaleX(1)";
  //   },
  // },
});

// 初期化の後で Swiper のインスタンスの on メソッドで登録
mySwiper.on("slideChangeTransitionStart", () => {
  const Header = document.querySelector(".js-header");
  Header.classList.toggle("is-drl");
  Header.classList.toggle("is-ascs");
});
// mySwiper.on("slideChangeTransitionEnd", () => {
//   const heroLogo = document.querySelector(".p-hero__logo");
//   heroLogo.classList.remove("is-animation");
// });

/* =================================================================
  モーダルウィンドウ
================================================================= */

$(".js-modaal-video").modaal({
  type: "video",
  animation_speed: 500,
  background_scroll: false,
  custom_class: "p-modal",
});

/* =================================================================
  ページ内リンク
================================================================= */

$(function () {
  $(".js-interlink , .js-interlink a").click(function () {
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var header = $(".js-header").innerHeight();
    var position = target.offset().top - header;
    $("body, html").animate(
      {
        scrollTop: position,
      },
      500
    );
    return false;
  });
});
