/* =================================================================

  ドロワーメニュー

================================================================= */

export const drawer = function () {
  const elem = document.querySelector(".js-drawer");
  elem.addEventListener("click", function (e) {
    const isExpanded = e.currentTarget.getAttribute("aria-expanded") !== "false";
    e.currentTarget.setAttribute("aria-expanded", !isExpanded);

    document.documentElement.classList.toggle(`is-drawerActive`);
  });

  const nav = document.querySelectorAll("#drawer a[href^='#']");
  for (let i = 0; i < nav.length; i++) {
    nav[i].addEventListener("click", function (e) {
      document.documentElement.classList.remove(`is-drawerActive`);
      elem.setAttribute("aria-expanded", false);
    });
  }
};
